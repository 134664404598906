
































































































































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadWidget, truncateContent, dateFormat } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import {
  IDashboardCount,
  ILogsCount,
  IEventActivity,
  ISenderID,
  IUser,
  IPeople,
  IBrands,
} from '@/types/types';
const authModule = namespace('auth');
const reportsModule = namespace('reports');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
const usersModule = namespace('users');
@Component({
  name: 'Dashboard',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    truncateContent,
    dateFormat,
  },
})
export default class Dashboard extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;

  @reportsModule.Getter('getDashboardCount') count!: IDashboardCount;
  @reportsModule.Getter('getDailyDeliveryStats') dailyReports!: Array<{
    key: string;
    logs: ILogsCount;
  }>;
  @smsModule.Getter('getPendingSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getAllEvents') events!: Array<IEventActivity>;
  @eventsModule.Getter('getAllPeople') people!: Array<IPeople>;
  @eventsModule.Getter('getAllBrands') brands!: Array<IBrands>;
  @eventsModule.Getter('getAnalytics') analytics!: IDashboardCount;
  @usersModule.Getter('getAllFavoriteIDs') favoriteIDs!: string[];

  placeholder =
    'https://res.cloudinary.com/llolollolo/image/upload/v1709131267/wear_ghana/k5cl77crqi1k1msh5yoa.png';

  options = {
    page: 1,
    limit: 15,
  };

  activeTab = 0;
  tabs: Array<{ text: string; image: string; index: number }> = [
    {
      text: 'Events',
      image: require('@/assets/images/confetti.png'),
      index: 1,
    },
    {
      text: 'People',
      image: require('@/assets/images/group.png'),
      index: 2,
    },
    {
      text: 'Brands',
      image: require('@/assets/images/badge.png'),
      index: 3,
    },
  ];

  @Watch('activeTab')
  onTabActiveChange(tabIndex: number): void {
    console.log({ tabIndex });
    switch (tabIndex) {
      case 0:
        this.$store.dispatch('events/list', {
          query: `?isPaginated=true&page=1&size=${this.options.limit}&sort=createdAt,ASC`,
        });
        break;
      case 1:
        this.$store.dispatch('events/people');
        break;
      case 2:
        this.$store.dispatch('events/brands');
        break;
      default:
        this.$store.dispatch('events/list', {
          query: `?isPaginated=true&page=1&size=${this.options.limit}&sort=createdAt,ASC`,
        });
        break;
    }
  }

  addFavorite(id: string | undefined): void {
    this.$store.dispatch('users/addFavorite', { eventId: id });
  }

  refreshPage(): void {
    this.$store.dispatch('events/list', {
      query: `?isPaginated=true&page=1&size=${this.options.limit}&sort=createdAt,ASC`,
    });
    this.$store.dispatch('events/people');
    this.$store.dispatch('events/brands');
  }

  created(): void {
    this.$store.dispatch('events/list', {
      query: `?isPaginated=true&page=1&size=${this.options.limit}&sort=createdAt,ASC`,
    });
    this.$store.dispatch('users/listFavorites');
    this.$store.dispatch('events/analytics');
  }
}
